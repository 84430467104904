<template>
    <div class="container">
<!--        <a v-if="showeditor === false" class="editbutton" @click="() => { showeditor = true }">edit</a>-->
        <div v-if="showeditor === false"><img style="width: 100%;" :src="srcinternal"></div>
<!--        <div v-if='showeditor === true' class="editor">-->
<!--            <PinturaEditor-->
<!--                v-bind="defaults"-->
<!--                :cropSelectPresetOptions="cropSelectPresetOptions"-->
<!--                :src="srcinternal"-->
<!--                v-on:pintura:close="handleEditorClose($event)">-->
<!--            </PinturaEditor>-->
<!--        </div>-->
        <div v-if='showeditor === true' class="editor-fullscreen-block">
          <div class="editor-fullscreen-content">
            <PinturaEditor
                v-bind="defaults"
                :cropSelectPresetOptions="cropSelectPresetOptions"
                :src="srcinternal"
                v-on:pintura:close="handleEditorClose($event)"
                :imageCropLimitToImage="false"
                :imageBackgroundColor="[1, 1, 1]">
            </PinturaEditor>
          </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, toRefs, watch } from 'vue';
    import { useStore } from "vuex";
    import { getEditorDefaults, setPlugins, plugin_crop} from '@pqina/pintura';
    setPlugins(plugin_crop);
    import { PinturaEditor } from '@pqina/vue-pintura';

    const props = defineProps({ src: String, imageEditor: Boolean });
    const emit = defineEmits(['on-data']);
    const { src, imageEditor } = toRefs(props);
    const srcinternal = ref(src.value);
    const showeditor = ref(imageEditor.value);
    const store = useStore();
    const apiPath = inject('apiPath');
    const imagesControl = inject('imagesControl');

    let editorDefaults = getEditorDefaults({
        layoutDirectionPreference: 'vertical',
        layoutVerticalToolbarPreference: 'bottom',
        layoutVerticalUtilsPreference: 'bottom',
        layoutVerticalControlTabsPreference: 'top',
        enableButtonClose: true,
        locale: { labelButtonExport: 'Save' },

        imageCropAspectRatio: 4 / 3,
        imageWriter: {
            store: (state, options, onprogress) =>
                new Promise((resolve, reject) => {
                    const { dest } = state;
                    const formData = new FormData();
                    formData.append('file', dest);
                    getOneTimeUploadUrl().then((url) => {
                        const request = new XMLHttpRequest();
                        const prelink = url;
                        console.log("PRELINK:", prelink);
                        let link = prelink.replace('https://upload.', 'https://') + '/public';
                        console.log("LINK",link);

                        console.log("SRCINTERAL",srcinternal.value);
                        console.log("URL",url);
                        request.open('POST', url);
                        request.upload.onprogress = onprogress;
                        request.onerror = () =>
                            reject('oh no something went wrong!');
                        request.ontimeout = () =>
                            reject('oh no request timed out!');
                        request.onload = () => {
                            if (request.status >= 200 && request.status < 300) {
                                state.store = request;
                                src.value = link;
                                srcinternal.value = link;
                                resolve(state);
                            } else {
                                reject('oh no something went wrong!');
                            }
                        };
                        request.send(formData);
                        imagesControl.setFromFile(link, dest).then((response) => {
                          //emit("editedImageUploadCompleted", link);
                          emit("editedImageUploadCompleted", response);
                        });
                        //showeditor.value = false;
                    });
                }),
        },
    });


    watch(() => store.state.imageEditorSave, (newValue, oldValue) => {
      if (newValue === true) {
        document.getElementsByClassName('PinturaButtonExport')[0].click();
        store.state.imageEditorSave = false;
      }
    });

    const defaults = ref(editorDefaults);

    const cropSelectPresetOptions = [
          [4 / 3, '4:3'],
        ];

    async function getOneTimeUploadUrl() {
        let response = await fetch(`${apiPath}cloudflareGetUploadLinkImage?token=${store.state.usertoken}`);
        return await response.text();
    }

    function handleEditorClose(e) {
        emit("editedImageClosed");
        //showeditor.value = false;
    }

</script>

<style lang="scss" scoped>
.container {
  position:relative;
}
.editbutton {
  position:absolute;
  top:4px;
  left:2px;
  z-index:1000;
  background-color:rgba(98, 89, 89, 0.8);
  color:white;
  border-radius: 10px;
  padding:4px;
}
.editor {
  height: 400px;
}
.editor-fullscreen-block {
  position: fixed;
  box-sizing: border-box;
  padding: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10; /* Make sure it's on top of other elements */
  justify-content: center;
  align-items: center;
}
.editor-fullscreen-content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.PinturaScrollable {
  background: #000000;
}
@import 'pintura.css';
</style>